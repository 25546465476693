<template>
  <div>
    <div v-for="(item, index) in experienceArray" v-bind:key="index">
      <div class="zhi">
        <div class="exp1">
          <div>工作经历{{ index + 1 }}</div>
        </div>
        <div
          class="allren"
          v-for="(itemes, indexes) in item"
          v-bind:key="indexes"
        >
          <div>{{ itemes.name }}</div>
          <div class="fensh">
            <div class="ren">
              <img
                v-if="indexes == 0"
                class="renimg"
                src="../assets/resumematch_ren.png"
                alt=""
              />
              <img
                v-else
                class="renimg"
                src="../assets/resumematch_ren2.png"
                alt=""
              />
              <div
                v-if="indexes == 0"
                :style="
                  'background:#5AAEF3;transform: translateX(' +
                  itemes.value * 10 +
                  '%);'
                "
                class="renimg2"
              ></div>
              <div
                v-else
                :style="
                  'background:#62D9AD;transform: translateX(' +
                  itemes.value * 10 +
                  '%);'
                "
                class="renimg2"
              ></div>
              <img
                class="renimg3"
                src="../assets/resumematch_ren_back2.png"
                alt=""
              />
            </div>
            <div class="bfclass">{{ itemes.value * 10 }}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      experienceArray: [],
      allmsg: "",
    };
  },
  mounted() {},
  methods: {
    childMeth(msg) {
      this.allmsg = JSON.parse(msg.job_exp_sim_objs);
      console.log("++++>",this.allmsg);
      let arr = [];
      //e.title_sim.toFixed(2)
      this.allmsg.forEach((e) => {
        arr.push([
          {
            name: "工作职位",
            value: e.title_sim.toFixed(1),
          },
          {
            name: "工作描述",
            value: e.content_sim.toFixed(1),
          },
        ]);
      });
      this.experienceArray = arr;
    },
  },
};
</script>
<style scoped>
.bfclass {
  font-size: 0.28rem;
  font-weight: 600;
  color: #fa9e11;
  line-height: 0.48rem;
}
.fensh {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.fensh > :first-child {
  margin-right: 0.25rem;
}
.renimg3 {
  position: absolute;
  height: 0.8rem;
  margin: 0.1rem;
  top: 0;
  left: 0;
  min-width: 5.3rem;
  z-index: 999;
  display: block;
}
.renimg2 {
  position: absolute;
  height: 1rem;
  z-index: 1000;
  top: 0;
  left: -100%;
  min-width: 5.3rem;
  display: block;
}
.renimg {
  position: absolute;
  height: 1rem;
  top: 0;
  left: 0;
  min-width: 5.3rem;
  z-index: 2000;
  display: block;
}
.ren {
  background-color: #ffffff;
  border: none;
  height: 1rem;
  min-width: 5.3rem;
  position: relative;
  overflow: hidden;
  margin-top: 0.1rem;
}
.zhi > :first-child {
  margin-bottom: 0.3rem;
}
.zhi {
  margin: 0.38rem;
  padding: 0.3rem;
  background-color: #ffffff;
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.4rem;
}
.exp1 {
  font-size: 0.32rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
}
</style>