<template>
  <div class="allmsgclass">
    <div :style="Number(backnum) < 0?'filter:blur(.15rem);':''" class="themsgclass">
      <div class="zhishu2">
        当前萝卜个人背景指数为<span class="fenclass">{{ backnum }}分</span
        >，等级为{{
          backnum > 60 ? "低风险" : backnum > 40 ? "中风险" : "高风险"
        }}
      </div>
      <div class="zhishuimgdiv">
        <div :class="backnum > 60 ? 'ncl3' : backnum > 40 ? 'ncl2' : 'ncl1'">
          <div>{{ backnum }}</div>
          <div class="fx">
            {{ backnum > 61 ? "低风险" : backnum > 41 ? "中风险" : "高风险" }}
          </div>
        </div>
      </div>
      <div class="boclass">
        萝卜背景指数反映了候选人的背景指标，指数越高说明匹配性越好。本指数仅为企业提供雇佣参考，请谨慎使用。
      </div>
    </div>
    <div v-if="Number(backnum) < 0">
      <div class="jiesuo">
        <div>
          <img src="../assets/resumematch_lock.png" alt="" />
        </div>
        <div>简历信息是否靠谱，背调查一查</div>
        <div>
          <div @click="useMeth" class="buy">立即使用</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    backnum: Number,
    phone:Number
  },
  methods:{
    useMeth(){
      if(!this.phone){
        this.$toast("没有该用户的手机信息");
      }else{
        sessionStorage.setItem("sub2msgMobile", this.phone);
        this.$router.push("/backtotwo");
      }
    }
  }
};
</script>
<style scoped>
.jiesuo > div {
  text-align: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.28rem;
}
.jiesuo {
  margin-top: .5rem;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.98;
}
.jiesuo img {
  width: 0.62rem;
  margin-bottom: 0.34rem;
}
.buy {
  display: inline-block;
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.28rem;
  padding: 0.26rem 0.58rem;
  background: linear-gradient(90deg, #ff8d35 0%, #ff5f18 100%);
  border-radius: 0.08rem;
  margin-top: 0.5rem;
}
.allmsgclass{
  position: relative;
}
.fenclass {
  color: #fa9e11;
  font-size: 0.32rem;
}
.boclass {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
}
.themsgclass {
  background-color: #ffffff;
  margin: 0.38rem;
  padding: 0.38rem 0.22rem;
}
.ncl1 {
  color: #dc143c;
}
.ncl2 {
  color: #800080;
}
.ncl3 {
  color: #0000ff;
}
.fx {
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 0.4rem;
  text-align: center;
}
.zhishu2 {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
  text-align: center;
}
.zhishuimgdiv {
  width: 4.66rem;
  height: 4.66rem;
  background-image: url("../assets/zhishu.png");
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.2rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff0000;
  line-height: 1.18rem;
  margin-left: 50%;
  transform: translateX(-50%);

  margin-top: 0.22rem;
  margin-bottom: 0.45rem;
}
</style>
