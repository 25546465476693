export default (canid, divid) => {
    let canvas = canid;
    let ctx = canvas.getContext("2d");
    let dpr = window.devicePixelRatio;

    canvas.width = divid.offsetWidth * dpr;
    canvas.height = divid.offsetHeight * dpr;

    canvas.style.width = divid.offsetWidth + "px";
    canvas.style.height = divid.offsetHeight + "px";
    // ctx.translate(canvas.width / (2 * dpr), canvas.height / (2 * dpr));
    ctx.scale(dpr, dpr);
    return {
        canvas,
        ctx,
        dpr
    }
}