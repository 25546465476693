<template>
  <div>
    <div id="abilitydiv">
      <canvas id="abilityid"></canvas>
    </div>
  </div>
</template>
<script>
import canvasjs from "../../util/canvas";
export default {
  data() {
    return {
      canvas: "",
      ctx: "",
      startX: "",
      startY: "",
      endX: "",
      endY: "",
      dpr:"",
      lineCenter:"",
      rectHeight:"",
      XArray:"",
      abilityArray:[],
      allmsg:""
    };
  },
  mounted() {

  },
  methods: {
    childMeth(msg){
      let msgs = Object.values(JSON.parse(msg.capacity_sim_obj));
      let arr = [],arr2 = ["薪资水平","工作年限","教育学历"];
      for(let m of msgs){
        arr.push(m);
      }
      this.allmsg = arr;
      let maxDrawValue = 70,maxValue = Math.max(...this.allmsg);
      let anValue = maxDrawValue/maxValue,arr3=[];
      for(let i = 0;i<this.allmsg.length;i++){
        arr3.push({
          name:arr2[i],
          value:anValue*this.allmsg[i],
          fraction:this.allmsg[i].toFixed(1)*10
        })
      }
      this.abilityArray = arr3;
      this.beginMeth();
    },
    beginMeth(){
      let canvasobj = canvasjs(
      document.getElementById("abilityid"),
      document.getElementById("abilitydiv")
    );
    this.canvas = canvasobj.canvas;
    this.ctx = canvasobj.ctx;
    this.dpr = canvasobj.dpr;

    this.startX = this.pxToRpx(0);
    this.startY = this.pxToRpx(5);
    this.endX = this.pxToRpx(0);
    this.endY = this.pxToRpx(80);
    this.rectHeight = this.pxToRpx(6);
    this.lineCenter = (this.endY-this.startY)/2+this.startY;

    this.XArray = [this.startY+this.pxToRpx(13),this.lineCenter,this.endY-this.pxToRpx(13)]


    this.drawLine();
    for(let i =0;i<this.abilityArray.length;i++){
        this.drawRect(this.abilityArray[i],this.XArray[i]);
    }
    },
    drawLine() {
      this.ctx.beginPath();
      this.ctx.moveTo(this.startX, this.startY);
      this.ctx.lineTo(this.endX, this.endY);
      this.ctx.strokeStyle = "#f1f1f1";
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
    },
    pxToRpx(width) {
      return (window.innerWidth / 100) * width;
    },
    drawRect(abilityArray,y){
        this.ctx.beginPath();
        this.ctx.fillStyle = "#3CD4E5";
        this.ctx.fillRect(this.startX,y,this.pxToRpx(abilityArray.value),this.rectHeight);
        this.drawText(abilityArray,y);
    },
    drawText(abilityArray,y){
       this.ctx.beginPath();
       this.ctx.textAlign = "left";
       this.ctx.textBaseline  = "middle";
       this.ctx.fillStyle = "#303133";
       this.ctx.font = this.pxToRpx(4)+"px self";
       this.ctx.fillText(abilityArray.name,this.startX+this.pxToRpx(1),y-this.pxToRpx(5));
       this.ctx.fillStyle ="#fa9e11";
       this.ctx.fillText(abilityArray.fraction+'%',this.startX+this.pxToRpx(abilityArray.value)+this.pxToRpx(2),y+this.rectHeight/2);
    },
  },
};
</script>
<style scoped>
#abilitydiv {
  height: 6rem;
  color: #f1f1f1;
}
</style>