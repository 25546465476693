<template>
  <div>
    <navigation msg="候选人胜任力匹配"></navigation>
    <div class="ckclass">此报告仅供参考，不作为入离职依据</div>
    <div class="peopleclass">
      <div class="allimg">
        <img
          v-if="allmsg.sex == '女'"
          class="imgone"
          src="../../assets/user_2.png"
          alt=""
        />
        <img v-else class="imgone" src="../../assets/user_1.png" alt="" />
        <img class="imgtwo" src="../../assets/shiming.png" alt="" />
      </div>
      <div class="m1">{{ allmsg.name }}</div>
      <div class="m2">{{ allmsg.job }}•{{ allmsg.company }}</div>
    </div>
    <div class="xinc">
      <div></div>
      <div>综合匹配得分：</div>
      <div class="xinfen">
        {{
          isNaN((allmsg.final_sim * 1).toFixed(1) * 10)
            ? "0"
            : (allmsg.final_sim * 1).toFixed(1) * 10
        }}%<span></span>
      </div>
    </div>
    <div class="topmsgclass">
      <score ref="scoreref"></score>
    </div>
    <div class="xinc">
      <div></div>
      <div>匹配岗位</div>
    </div>
    <div class="ppclass">
      <div class="pptopclass">
        <div class="pp1">
          <div>{{ subscribeInfo.jop }}</div>
          <div>
            <div @click="isput = !isput">{{ isput ? "收起" : "展开" }}</div>
            <van-icon v-show="!isput" name="arrow-up" />
            <van-icon v-show="isput" name="arrow-down" />
          </div>
        </div>
        <div class="pp2">
          {{ subscribeInfo.salary ? subscribeInfo.salary : "未填写薪资" }}
        </div>
        <div class="pp3">
          <div>
            <img src="" alt="" />
            <div>{{ subscribeInfo.area }}</div>
          </div>
          <div>
            <img src="" alt="" />
            <div>{{ subscribeInfo.years_working }}</div>
          </div>
          <div>
            <img src="" alt="" />
            <div>{{ subscribeInfo.education_background }}</div>
          </div>
        </div>
      </div>
      <div
        :style="
          isput
            ? 'height:auto;border-top:solid 1px #5d78b3;padding: .38rem .44rem;'
            : 'height:0;padding:0;border:none;'
        "
        class="ppbottom"
      >
        <div>匹配职位描述/职责</div>
        <div>{{ subscribeInfo.desc }}</div>
      </div>
    </div>

    <div class="ppmenu">
      <div>匹配企业信息</div>
      <div>
        <div @click="isput1 = !isput1">{{ isput1 ? "收起" : "展开" }}</div>
        <van-icon v-show="!isput1" name="arrow-up" />
        <van-icon v-show="isput1" name="arrow-down" />
      </div>
    </div>
    <div v-show="isput1" class="xiangxiclass">
      <div v-if="allmsg.company">{{ allmsg.company }}</div>
      <div v-else class="nomsgdivclass">
        <img
          class="nomsgclass"
          src="../../assets/resumematch_nomsg.png"
          alt=""
        />
        <div>暂无数据</div>
      </div>
    </div>
    <div class="ppmenu">
      <div>匹配部门文化</div>
      <div>
        <div @click="isput2 = !isput2">{{ isput2 ? "收起" : "展开" }}</div>
        <van-icon v-show="!isput2" name="arrow-up" />
        <van-icon v-show="isput2" name="arrow-down" />
      </div>
    </div>
    <div v-show="isput2" class="xiangxiclass">
      <div v-if="companyInfo.company">{{ companyInfo.company }}</div>
      <div v-else class="nomsgdivclass">
        <img
          class="nomsgclass"
          src="../../assets/resumematch_nomsg.png"
          alt=""
        />
        <div>暂无数据</div>
      </div>
    </div>
    <div class="ppmenu">
      <div>匹配部门建设</div>
      <div>
        <div @click="isput3 = !isput3">{{ isput3 ? "收起" : "展开" }}</div>
        <van-icon v-show="!isput3" name="arrow-up" />
        <van-icon v-show="isput3" name="arrow-down" />
      </div>
    </div>
    <div v-show="isput3" class="xiangxiclass">
      <div v-if="companyInfo.company_build">
        {{ companyInfo.company_build }}
      </div>
      <div v-else class="nomsgdivclass">
        <img
          class="nomsgclass"
          src="../../assets/resumematch_nomsg.png"
          alt=""
        />
        <div>暂无数据</div>
      </div>
    </div>
    <div class="ppmenu">
      <div>匹配企业文化</div>
      <div>
        <div @click="isput4 = !isput4">{{ isput4 ? "收起" : "展开" }}</div>
        <van-icon v-show="!isput4" name="arrow-up" />
        <van-icon v-show="isput4" name="arrow-down" />
      </div>
    </div>
    <div v-show="isput4" class="xiangxiclass">
      <div v-if="companyInfo.company_culture">
        {{ companyInfo.company_culture }}
      </div>
      <div v-else class="nomsgdivclass">
        <img
          class="nomsgclass"
          src="../../assets/resumematch_nomsg.png"
          alt=""
        />
        <div>暂无数据</div>
      </div>
    </div>
    <!-- <div :style="lock ? '' : 'filter:blur(.15rem);'"> -->
    <div class="xinxiparent">
      <div :style="lock ? '' : 'filter:blur(.15rem);'">
        <div class="xinc">
          <div></div>
          <div>期望工作匹配度：</div>
          <div class="xinfen">
            {{ Number(allmsg.expect_sim).toFixed(1) * 10 }}%
          </div>
        </div>
        <div class="class2 class4">
          <workcanvas ref="workref"></workcanvas>
        </div>
        <div class="xinc">
          <div></div>
          <div>个人能力匹配度：</div>
          <div class="xinfen">
            {{ Number(allmsg.capacity_sim).toFixed(1) * 10 }}%
          </div>
        </div>
        <div class="class2 class3">
          <abilitycanvas ref="abilityref"></abilitycanvas>
        </div>
        <div class="xinc">
          <div></div>
          <div>工作经历匹配度：</div>
          <div class="xinfen">
            {{ Number(allmsg.job_exp_sim).toFixed(1) * 10 }}%
          </div>
        </div>
        <experiencecanvas ref="experref"></experiencecanvas>
      </div>
      <div class="jiesuo" v-if="!lock">
        <div>
          <img src="../../assets/resumematch_lock.png" alt="" />
        </div>
        <div>购买后即可查看所有分析</div>
        <div>
          <div @click="buymeth" class="buy">立即购买</div>
        </div>
      </div>
    </div>
    <div class="ppmenu">
      <div>风险指数</div>
    </div>
    <pingfen :phone="allmsg.mobile" :backnum="allmsg.points"></pingfen>
    <ispayResumeMatch ref="matchid"></ispayResumeMatch>
  </div>
</template>
<script>
import { Dialog } from "vant";
import score from "../../components/resumeMatchCanvas/score.vue";
import workcanvas from "../../components/workcanvas.vue";
import abilitycanvas from "../../components/abilitycanvas.vue";
import experiencecanvas from "../../components/experiencecanvas.vue";
import ispayResumeMatch from "../../components/ispayResumeMatch/index.vue";
import pingfen from "../../components/pingfen.vue";
export default {
  components: {
    score,
    workcanvas,
    abilitycanvas,
    experiencecanvas,
    ispayResumeMatch,
    pingfen,
  },
  data() {
    return {
      lock: false,
      ispay: "-1",
      allmsg: {},
      isput: false, //是否收起（匹配岗位）
      isput1: false, //是否收起（企业信息）
      isput2: false, //是否收起（部门文化）
      isput3: false, //是否收起（部门建议）
      isput4: false, //是否收起（企业文化）
      backnum: 80,
      subscribeInfo: {},
      companyInfo: {},
    };
  },
  mounted() {
    if (this.$route.query.resumeassistant == 1) {
      let msg = JSON.parse(this.$route.query.allmsg);
      //企业信息查询

      //人才库进入
      this.$http
        .post("/firm/v1/talent_center/getResumeInfo", {
          reqType: "resume",
          id: msg.id,
        })
        .then((res) => {
          console.log(JSON.parse(res.data).data);
          let themsg = JSON.parse(res.data).data;
          this.allmsg = {
            assessment:JSON.parse(themsg.postInfo.assessment),
            capacity_sim:themsg.postInfo.capacity_sim,
            capacity_sim_obj:themsg.postInfo.capacity_sim_obj,
            company:"",
            companyInfo:themsg.companyInfo,
            desc_sim:themsg.postInfo.desc_sim,
            estimated_salary:themsg.postInfo.estimated_salary,
            expect_salary:themsg.postInfo.expect_salary,
            expect_sim:themsg.postInfo.expect_sim,
            expect_sim_obj:themsg.postInfo.expect_sim_obj,
            final_sim:themsg.postInfo.final_sim,
            isPay:1,
            job:themsg.postInfo.job,
            job_exp_sim:themsg.postInfo.job_exp_sim,
            job_exp_sim_objs:themsg.postInfo.job_exp_sim_objs,
            mobile:Number(themsg.postInfo.mobile),
            name:themsg.postInfo.name,
            points:Number(themsg.points),
            proj_exp_sim_objs:themsg.postInfo.proj_exp_sim_objs,
            sex:themsg.postInfo.sex,
            work_salary:themsg.postInfo.work_salary,
            subscribeInfo:themsg.subscribeInfo
          }
          this.lock = true;
          this.subscribeInfo = this.allmsg.subscribeInfo; //匹配岗位
          this.companyInfo = this.allmsg.companyInfo;
          console.log(this.allmsg);
          this.$refs.scoreref.childMeth(this.allmsg);
          this.$refs.workref.childMeth(this.allmsg);
          this.$refs.abilityref.childMeth(this.allmsg);
          this.$refs.experref.childMeth(this.allmsg);
        });
    } else {
      this.$http
        .post("/firm/v1/talent_center/resumeMate", {
          reqType: "resume",
          resumeId: atob(this.$route.query.id),
          subId: atob(this.$route.query.sub_id),
        })
        .then((res) => {
          this.allmsg = JSON.parse(res.data).data;
          console.log(this.allmsg);
          this.lock = this.ispay = this.allmsg.isPay;
          this.subscribeInfo = this.allmsg.subscribeInfo; //匹配岗位
          this.companyInfo = this.allmsg.companyInfo;
          console.log(this.allmsg);
          this.$refs.scoreref.childMeth(this.allmsg);
          this.$refs.workref.childMeth(this.allmsg);
          this.$refs.abilityref.childMeth(this.allmsg);
          this.$refs.experref.childMeth(this.allmsg);
        });
    }
  },
  methods: {
    //获取企业信息
    buymeth() {
      if (this.ispay == "-1") {
        Dialog.alert({
          title: "提醒",
          message: "该简历没有内容，不建议购买。",
        }).then(() => {
          // on close
        });
        return;
      }
      this.$refs.matchid.childMeth(
        this.allmsg,
        atob(this.$route.query.id),
        2,
        this.$route.query.evalmsg
      );
    },
  },
};
</script>
<style scoped>
.xinxiparent {
  position: relative;
}

.nomsgdivclass {
  text-align: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
  padding: 0.28rem 0;
}
.nomsgclass {
  width: 2.32rem;
  margin-bottom: 0.2rem;
}
.xiangxiclass {
  background-color: #ffffff;
  margin: 0.38rem;
  padding: 0.26rem 0.42rem;
  border-radius: 0.16rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.32rem;
}
.ppmenu {
  font-size: 0.36rem;
  font-weight: 600;
  color: #303133;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.38rem;
}
.ppmenu > :nth-child(2) {
  font-size: 0.32rem;
  font-weight: 400;
  color: #4571d0;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
}
.ppmenu > :nth-child(2) > :first-child {
  margin-right: 0.2rem;
}
.pptopclass {
  padding: 0.38rem 0.44rem;
}
#areaid {
  background-color: transparent;
  border: none;
  font-size: 0.28rem;
  font-weight: 400;
  padding: 0;
  width: 100%;
}
.ppbottom {
  font-size: 0.32rem;
  font-weight: 600;
  color: #e2ebff;
  line-height: 0.5rem;
  padding: 0.38rem 0.44rem;
  border-top: solid 1px #5d78b3;
}
.pp3 {
  font-size: 0.28rem;
  font-weight: 400;
  color: #dbe6ff;
  line-height: 0.4rem;
  display: flex;
  align-items: center;
}
.pp3 > div {
  display: flex;
  align-items: center;
  margin-right: 0.3rem;
}
.pp2 {
  margin-top: 0.22rem;
  margin-bottom: 0.3rem;
  font-size: 0.32rem;
  font-weight: 600;
  color: #fa9e11;
  line-height: 0.48rem;
}
.pp1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.36rem;
  font-weight: 500;
  color: #dbe6ff;
  line-height: 0.48rem;
}
.pp1 > :nth-child(2) {
  font-size: 0.32rem;
  font-weight: 400;
  color: #dfe1e5;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
}
.pp1 > :nth-child(2) > :first-child {
  margin-right: 0.2rem;
}
.ppclass {
  margin: 0.38rem;
  border-radius: 0.16rem;
  overflow: hidden;
  background-image: url("../../assets/resumematch_back.png");
  background-size: 100% auto;
}
.class4 {
  padding-bottom: 0.3rem;
}
.class3 {
  padding: 0.3rem;
  padding-top: 0;
}
.peopleclass {
  margin: 0.34rem;
  margin-bottom: 0.3rem;
  padding-top: 0.96rem;
  padding-bottom: 0.38rem;
  background-color: #ffffff;
  border-radius: 0.16rem;
}
.ckclass {
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.33rem;
  background: #fe9158;
  text-align: center;
  padding: 0.14rem 0;
}
.buy {
  display: inline-block;
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.28rem;
  padding: 0.26rem 0.58rem;
  background: linear-gradient(90deg, #ff8d35 0%, #ff5f18 100%);
  border-radius: 0.08rem;
  margin-top: 0.5rem;
}
.jiesuo > div {
  text-align: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.28rem;
}
.jiesuo {
  margin-top: 0.5rem;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.98;
}
.jiesuo img {
  width: 0.62rem;
  margin-bottom: 0.34rem;
}
.experience {
  margin: 0.38rem;
}
.class2 {
  background-color: #ffffff;
  margin: 0.38rem;
  border-radius: 0.16rem;
}
.xinc {
  display: flex;
  align-items: center;
  font-size: 0.36rem;
  font-weight: 600;
  color: #303133;
  line-height: 0.48rem;
  margin: 0.3rem 0;
  margin-left: 0.38rem;
}
.xinfen {
  font-size: 0.36rem;
  font-weight: 600;
  color: #ec5d33;
  line-height: 0.48rem;
}
.xinfen span {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
}
.heng {
  margin-top: 0.36rem;
  margin-bottom: 0.32rem;
  background-color: #eeeff1;
  height: 1px;
}
.m1 {
  font-size: 0.4rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
  margin-top: 0.4rem;
  text-align: center;
}
.m2 {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.33rem;
  margin-top: 0.08rem;
  text-align: center;
}
.allimg {
  width: 1.06rem;
  height: 1.06rem;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
}
.imgone {
  width: 1.06rem;
  height: 1.06rem;
}
.imgtwo {
  position: absolute;
  width: 1.08rem;
  bottom: -0.2rem;
  left: 50%;
  transform: translateX(-50%);
}
.topmsgclass {
  background-color: #ffffff;
  margin: 0.38rem;
  border-radius: 0.16rem;
}
</style>