<template>
  <div>
    <div @click="beginMeth" id="scorediv">
      <canvas id="scoreid"></canvas>
    </div>
  </div>
</template>
<script>
import canvasmeth from "../../../util/canvas";
export default {
  data() {
    return {
      canvas: "",
      ctx: "",
      dpr: window.devicePixelRatio,
      Ry: "",
      arr: [],
      obj: {},
      color: [
        ["#667EFF", "#A646DB"],
        ["#24D8ED", "#4A67FB"],
        ["#F8D68D", "#EE7B81"],
      ],
      scoreArray: [],
      allmsg:"",
      fontsize:"4", //字体大小（百分比）
      fontsize2:"4", //字体大小（详情评分）
    };
  },
  mounted() {
    
  },
  methods: {
    childMeth(msg){
      this.allmsg = msg;
      let arr = [this.allmsg.expect_sim,this.allmsg.capacity_sim,this.allmsg.job_exp_sim];
      let arr2 = [],arr3=["期望工作","个人能力","工作经历"];
      for(let i = 0;i<arr.length;i++){
        arr2.push({
          name:arr3[i],
          value:arr[i]*10,
          score:Number(arr[i]).toFixed(2)
        })
      }
      this.scoreArray = arr2;
      this.beginMeth();
    },
    beginMeth() {
      let canobj = canvasmeth(
        document.getElementById("scoreid"),
        document.getElementById("scorediv")
      );
      this.canvas = canobj.canvas;
      this.ctx = canobj.ctx;

      let canWidth = parseFloat(this.canvas.style.width) - 30;
      //每分占多少
      let an = 12;
      this.anR = canWidth / 3 / 2 - an;
      this.Dx = this.anR + an;
      this.Dy = parseFloat(this.canvas.style.height) / 2.5;
      let x = this.Dx;

      let aa = 0,
        aa1 = this.scoreArray[0].value,
        bb = 0,
        bb1 = this.scoreArray[1].value,
        cc = 0,
        cc1 = this.scoreArray[2].value;
      let inter = setInterval(() => {
        x = this.Dx;
        if (aa < aa1) {
          this.scoreArray[0].value = aa += 1;
          aa++;
        }
        if (bb < bb1) {
          this.scoreArray[1].value = bb += 1;
          bb++;
        }
        if (cc < cc1) {
          this.scoreArray[2].value = cc += 1;
          cc++;
        }
        if (aa > aa1 && bb > bb1 && cc > cc1) {
          clearInterval(inter);
        }
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        for (let i = 0; i < this.scoreArray.length; i++) {
          this.drawScore(this.scoreArray[i], x, this.color[i], "draw" + i);
          x += this.Dx * 2 + this.pxToRpx(4);
        }
      }, 20);
    },
    pxToRpx(width) {
      return (window.innerWidth / 100) * width;
    },
    getAnMsg(value) {
      let a = 2;
      let b = parseFloat(a / 100, 2);
      return 1.5 - value * b;
    },
    // 标题类目
    drawTitle(scoreArray, x) {
      this.ctx.beginPath();
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.fillColor = "#303133";

      this.ctx.font = this.pxToRpx(this.fontsize2) + "px self";
      this.ctx.fillText(
        scoreArray.name + " " + scoreArray.value + "%",
        x,
        this.Dy + this.pxToRpx(20)
      );
    },
    drawScoreMsg(scoreArray, x, color) {
      this.ctx.beginPath();
      this.ctx.arc(
        x,
        this.Dy,
        this.anR,
        1.5 * Math.PI,
        this.getAnMsg(scoreArray.value) * Math.PI,
        true
      );
      let a = 20;
      let gradient = this.ctx.createLinearGradient(
        x - this.pxToRpx(a),
        this.Dy,
        x + this.pxToRpx(a),
        this.Dy
      );
      gradient.addColorStop(0, color[0]);
      gradient.addColorStop(1, color[1]);
      this.ctx.strokeStyle = gradient;
      this.ctx.lineWidth = 12;
      this.ctx.lineCap = "round";
      this.ctx.stroke();
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.font = "bold " + this.pxToRpx(this.fontsize) + "px self";
      this.ctx.fillText(scoreArray.value + "%", x, this.Dy);
      this.drawTitle(scoreArray, x);
    },
    drawScore(scoreArray, x, color) {
      this.ctx.beginPath();
      this.ctx.arc(x, this.Dy, this.anR, 0 * Math.PI, 2 * Math.PI, true);
      this.ctx.strokeStyle = "rgb(223,227,235)";
      this.ctx.lineWidth = 12;
      this.ctx.stroke();
      this.drawScoreMsg(scoreArray, x, color);
    },
  },
};
</script>
<style scoped>
#scorediv {
  height: 3.8rem;
}
</style> 