<template>
  <div>
    <div @click="beginmeth" id="workdiv">
      <canvas id="workid"></canvas>
    </div>
  </div>
</template>
<script>
import jsPDF from 'jspdf';
import canvasjs from "../../util/canvas";
export default {
  data() {
    return {
      canvas: "",
      ctx: "",
      lowerLineX: "",
      lowerLineY: "",
      lowerLineEndX: "",
      lowerLineEndY: "",
      xarr: [],
      allmsg:""
    };
  },
  mounted() {
    
  },
  methods: {
    childMeth(msg){
      this.allmsg = Object.values(JSON.parse(msg.expect_sim_obj));
      let arr2 = [];
      for(let msg of this.allmsg){
        arr2.push(msg);
      }
      this.allmsg = arr2;
      let maxDrawValue = 40,maxValue = Number(Math.max(...this.allmsg));

      let anValue = maxDrawValue/maxValue,colorArr=["#5AAEF3","#62D9AD","#5B6E96"],msgArr=["期望薪资","期望城市","期望职位"];
      let arr = [];
      for(let i =0;i<this.allmsg.length;i++){
        arr.push({
          name:msgArr[i],
          value:anValue*this.allmsg[i]+12,
          score:this.allmsg[i].toFixed(1)*10,
          color:colorArr[i]
        })
      }
      this.workArray = arr;

      this.beginmeth();
    },
    beginmeth() {
      let canjs = canvasjs(
        document.getElementById("workid"),
        document.getElementById("workdiv")
      );
      this.canvas = canjs.canvas;
      this.ctx = canjs.ctx;

      this.lowerLineX = this.pxToRpx(5);
      this.lowerLineY = this.pxToRpx(70);
      this.lowerLineEndX = this.lowerLineX + this.pxToRpx(80);
      this.lowerLineEndY = this.lowerLineY;

      // 获取 第一个位置 中心点 第三个位置
      this.xarr = this.getXArr();



      let aa = 0,
        aa1 = this.workArray[0].value,
        bb = 0,
        bb1 = this.workArray[1].value,
        cc = 0,
        cc1 = this.workArray[2].value;

      let inter = setInterval(() => {
        if (aa < aa1) {
          this.workArray[0].value = aa += 1;
          aa++;
        }
        if (bb < bb1) {
          this.workArray[1].value = bb += 1;
          bb++;
        }
        if (cc < cc1) {
          this.workArray[2].value = cc += 1;
          cc++;
        }
        if (aa > aa1 && bb > bb1 && cc > cc1) {
          clearInterval(inter);
        }

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.drawLine();
        for (let i = 0; i < this.workArray.length; i++) {
          this.drawLineArray(this.workArray[i], this.xarr[i]);
        }
      }, 20);
    },
    getXArr() {
      let allline = this.lowerLineEndX - this.lowerLineX;
      let center = allline / 2 + this.lowerLineX;
      return [
        this.lowerLineX + this.pxToRpx(10),
        center,
        this.lowerLineEndX - this.pxToRpx(10),
      ];
    },
    pxToRpx(width) {
      return (window.innerWidth / 100) * width;
    },
    drawLine() {
      this.ctx.beginPath();
      this.ctx.lineWidth = 1;
      this.ctx.moveTo(this.lowerLineX, this.lowerLineY);
      this.ctx.lineTo(this.lowerLineEndX, this.lowerLineEndY);
      this.ctx.strokeStyle = "#EDEEF1";
      this.ctx.stroke();
    },
    // 提示
    drawTitle(workArray, x) {
      this.ctx.beginPath();
      this.ctx.fillStyle = "#606266";
      this.ctx.font = this.pxToRpx(4) + "px self";
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.fillText(workArray.name, x, this.lowerLineY + this.pxToRpx(6));
    },
    // 文字
    drawFont(workArray, x) {
      this.ctx.beginPath();
      this.ctx.fillStyle = "white";
      this.ctx.font = this.pxToRpx(4) + "px self";
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.fillText(
        workArray.score + "%",
        x,
        this.lowerLineY - this.pxToRpx(workArray.value)
      );
      this.drawTitle(workArray, x);
    },
    // 画圆
    drawArc(workArray, x) {
      this.ctx.beginPath();
      this.ctx.arc(
        x,
        this.lowerLineY - this.pxToRpx(workArray.value),
        this.pxToRpx(10),
        0,
        2 * Math.PI,
        true
      );
      this.ctx.fillStyle = workArray.color;
      this.ctx.fill();
      this.drawFont(workArray, x);
    },
    // 画线
    drawLineArray(workArray, x) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, this.lowerLineY);
      this.ctx.lineTo(x, this.lowerLineY - this.pxToRpx(workArray.value));
      this.ctx.strokeStyle = workArray.color;
      this.ctx.lineWidth = 6;
      this.ctx.stroke();
      this.drawArc(workArray, x);
    },
  },
};
</script>
<style scoped>
#workdiv {
  height: 6rem;
}
</style>